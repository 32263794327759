<template>
  <Card class="max-w-prose self-center">
    <CardHeader icon="hazcheck_h">
      {{ $t("index.welcome_title") }}
    </CardHeader>
    <q-card-section class="q-py-md">
      <p class="q-ma-none whitespace">
        {{
          store.showWsc
            ? $t("index.welcome_text_wsc")
            : $t("index.welcome_text")
        }}
      </p>
    </q-card-section>
  </Card>
  <q-space />
  <div
    class="flex justify-center gap-md"
    style="max-height: 200px; overflow: hidden"
  >
    <Logo v-if="store.showWsc" width="115px" variant="wsc" />
    <Logo
      width="160px"
      :style="[
        store.showWsc ? 'margin-bottom: -1px; ' : '',
        'align-self: center',
      ]"
      variant="ncb-solac"
    />
  </div>
</template>

<script setup lang="ts">
import { CardHeader, Logo } from "#components";
import { useUserStore } from "#imports";

const store = useUserStore();
</script>
